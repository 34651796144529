import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { api, API } from "../api/api";
import somethingSlice from "../slices/tmpPatternSlice";
import { removeNotAuthorizedUser } from "./middleware/removeNotAuthorizedUser";

export const rootReducer = combineReducers({
  somethings: somethingSlice,
  [api.reducerPath]: api.reducer,
});

export const middleware = [api.middleware, removeNotAuthorizedUser];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = Omit<ReturnType<typeof store.getState>, typeof API>;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Application, ApplicationValidationResponse, SubmitApplicationRequest } from "../api/beachApi";

type TSomethingState = {
  type: ApplicationValidationResponse["type"],
  errorMessage: string;
  application: SubmitApplicationRequest,
  newListFilter: string;
  approvedListFilter: string;
  canceledListFilter: string;
  editApplication: Application;
  cancelReason: string;
};

export const initialApplicationState = {
  pesel: "",
  firstName: "",
  lastName: "",
  birthdayYear: undefined,
  email: "",
  clubId: "",
  phoneNumber: "",
  imagePath: ""
};

export const initialEditApplicationState: Application = {
  id: "",
  pesel: "",
  firstName: "",
  lastName: "",
  birthdayYear: 0,
  email: "",
  clubId: "",
  phoneNumber: "",
  imagePath: "",
  licenceNumber: "",
  type: "NEW",
  status: "NEW",
  batchGenerated: false,
  cancelReason: "",
  createdDate: "",
  updatedDate: ""
};

const initialState: TSomethingState = {
  type: undefined,
  errorMessage: "",
  application: initialApplicationState,
  newListFilter: "",
  approvedListFilter: "",
  canceledListFilter: "",
  editApplication: initialEditApplicationState,
  cancelReason: ""
};

export const somethingSlice = createSlice({
  name: "SOMETHING",
  initialState,
  reducers: {
    setData: <Property extends keyof TSomethingState>(
      state: TSomethingState,
      {
        payload
      }: PayloadAction<{ property: Property; value: TSomethingState[Property] }>
    ) => {
      const { property, value } = payload;
      state[property] = value;
    },
    setApplicationProperty: <Property extends keyof SubmitApplicationRequest>(
      state: TSomethingState,
      {
        payload
      }: PayloadAction<{ property: Property; value: SubmitApplicationRequest[Property] }>
    ) => {
      const { property, value } = payload;
      state.application[property] = value;
    },
    setEditApplicationProperty: <Property extends keyof Application>(
      state: TSomethingState,
      {
        payload
      }: PayloadAction<{ property: Property; value: Application[Property] }>
    ) => {
      const { property, value } = payload;
      state.editApplication[property] = value;
    }
  }
});

export const { setData, setApplicationProperty, setEditApplicationProperty } = somethingSlice.actions;

export const selectSomethingState = (state: any) => state.somethings;

export default somethingSlice.reducer;

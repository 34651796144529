import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {cacheTags} from "./cacheTags";
import { getAccessToken } from "../utils/userData";

export const API = "api";

const baseQuery = fetchBaseQuery({
  baseUrl: "/api",
  prepareHeaders: (headers) => {
    const accessToken = getAccessToken();

    if (!!accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }

    return headers;
  },
});

export const api = createApi({
  reducerPath: API,
  tagTypes: Object.values(cacheTags),
  baseQuery: baseQuery,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});

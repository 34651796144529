import React, { FC } from "react";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useDeleteApplicationByIdMutation, useRestoreApplicationMutation } from "../../api/beachApi";

const Actions: FC<{ row: any }> = ({ row }) => {
  const [visible, setVisible] = React.useState(false);

  const [deleteApplicationById] = useDeleteApplicationByIdMutation();
  const [restoreApplication] = useRestoreApplicationMutation();

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Button icon={PrimeIcons.HISTORY} title="Przywróć" tooltip="Przywróć" tooltipOptions={{position: "left"}} onClick={() => restoreApplication({ id: row.id })} />
        <Button icon={PrimeIcons.TRASH} title="Usuń" tooltip="Usuń" tooltipOptions={{position: "left"}} severity="danger" onClick={() => setVisible(true)} />
        <ConfirmDialog
          visible={visible}
          onHide={() => setVisible(false)}
          message="Jesteś pewny, że chcesz usunąć ten wniosek?"
          header="Potwierdzenie"
          acceptLabel="Tak"
          rejectLabel="Nie"
          accept={() => deleteApplicationById({ id: row.id })}
        />
      </div>
    </>
  );
};

const canceledListActionTemplate = (row: any) => <Actions row={row} />;
export default canceledListActionTemplate;

import React, { FC } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { MenuItem, MenuItemOptions } from "primereact/menuitem";
import { routes } from "../app/routes";
import { clearAccessToken } from "../utils/userData";
import { Image } from "primereact/image";

const Template: FC<{ item: MenuItem; options: MenuItemOptions }> = ({
                                                                      item,
                                                                      options
                                                                    }) => {
  return (
    <NavLink
      to={`${item.url}`}
      className={({
                    isActive,
                    isPending
                  }) => isPending ? options.className : isActive ? options.className + " nav-link-active" : options.className}
      onClick={options.onClick}
    >
      <i className={options.iconClassName} />{" "}
      <span className={options.labelClassName}>{item.label}</span>
    </NavLink>
  );
};

export const template = (item: MenuItem, options: MenuItemOptions) => (
  <Template item={item} options={options} />
);

const items = [
  {
    label: "Wnioski nowe",
    icon: "pi pi-fw pi-file",
    url: "/admin/new",
    template
  },
  {
    label: "Wnioski zaakceptowane",
    icon: "pi pi-fw pi-pencil",
    url: "/admin/approved",
    template
  },
  {
    label: "Wnioski anulowane",
    icon: "pi pi-fw pi-user",
    url: "/admin/canceled",
    template
  }
];

const Nav = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    clearAccessToken();
    navigate(`/${routes.LOGIN}`);
  };

  return (
    <>
      <Menubar
        start={
          <div style={{ marginRight: 10, fontWeight: "bold", display: "flex" }}>
            <Image src="/mwzps.png" width="150" onClick={() => navigate(`/${routes.ADMIN}`)} style={{cursor: "pointer"}} />
          </div>
        }
        model={items}
        end={
          <Button
            label="Wyloguj"
            icon="pi pi-power-off"
            onClick={handleLogout}
          />
        }
      />
    </>
  );
};

export default Nav;
import React, { CSSProperties, FC } from "react";
import { useAppDispatch } from "../../app/hooks";
import { InputText } from "primereact/inputtext";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

type TTextInputProps = {
  id: string;
  name: string;
  value: string;
  label: string;
  setProperty: ActionCreatorWithPayload<any>;
  style?: CSSProperties;
  outerStyle?: CSSProperties;
};

const TextInput: FC<TTextInputProps> = ({
                                          id,
                                          name,
                                          value,
                                          label,
                                          setProperty,
                                          style,
                                          outerStyle
                                        }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="field pt-2 pb-2 w-full" style={outerStyle}>
        <span className="p-float-label">
          <InputText
            id={id}
            value={value}
            onChange={(e) =>
              dispatch(setProperty({ property: name, value: e.target.value }))
            }
            style={style}
            className={"w-full"}
          />
          <label htmlFor={name}>{label}</label>
        </span>
      </div>
    </>
  );
};

export default TextInput;

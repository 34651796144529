import React, { FC } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import canceledListActionTemplate from "./Actions";
import { Application, useFindApplicationListQuery } from "../../api/beachApi";
import TextInput from "../../components/form/TextInput";
import { selectSomethingState, setData } from "../../slices/tmpPatternSlice";
import { useAppSelector } from "../../app/hooks";
import { formatDate } from "../../utils/dateUtils";
import { Badge } from "primereact/badge";

const columns = [
  { hidden: true, field: "firstName", header: "Imię" },
  { hidden: true, field: "lastName", header: "Nazwisko" },
  { field: "name", header: "Imię i nazwisko", body: (row: Application) => row.firstName + " " + row.lastName },
  { field: "pesel", header: "PESEL" },
  { field: "licenceNumber", header: "Numer licencji" },
  { field: "birthdayYear", header: "Rocznik" },
  { field: "clubName", header: "Klub" },
  {
    field: "type",
    header: "Typ wniosku",
    style: { textAlign: "center" },
    body: (row: Application) => <Badge value={row.type === "NEW" ? "Nowy" : "Duplikat"}
                                       style={{ backgroundColor: row.type === "NEW" ? "black" : "orange" }} />
  }, { field: "cancelReason", header: "Powód anulowania" },
  {
    field: "createdDate",
    header: "Złożony",
    body: (row: Application) => row.createdDate ? formatDate(new Date(row.createdDate)) : ""
  },
  {
    field: "updatedDate",
    header: "Anulowany",
    body: (row: Application) => row.updatedDate ? formatDate(new Date(row.updatedDate)) : ""
  },
  { field: "", header: "Akcje", body: canceledListActionTemplate }
];

const CanceledList: FC<{}> = ({}) => {
  const { canceledListFilter } = useAppSelector(selectSomethingState);

  const { data, isLoading } = useFindApplicationListQuery({ status: "CANCELED" });

  return (
    <>
      <TextInput
        id="filter-canceled-list"
        name="canceledListFilter"
        label=""
        value={canceledListFilter}
        setProperty={setData}
        style={{ width: "100%" }}
        outerStyle={{ marginBottom: 0 }}
      />
      <TableWrapper
        header="Wnioski anulowane"
        sortField="updatedDate"
        isLoading={isLoading}
        data={data}
        columns={columns}
        filter={canceledListFilter}
        stateKey={"canceled-list"}
      />
    </>
  );
};

export default CanceledList;

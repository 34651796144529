import React, { FC, useEffect } from "react";
import "./Application.css";
import { Message } from "primereact/message";
import { useReadAppSettingQuery } from "../../api/beachApi";
import { useNavigate } from "react-router-dom";

export const Maintenance: FC = () => {
  const navigate = useNavigate();

  const {data, isLoading, isSuccess} = useReadAppSettingQuery({type: "SUBMIT_APPLICATION_ENABLED"});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!data?.value) {
      navigate(`/`);
    }
  }, [isSuccess]);

  return (
    <div className="flex justify-content-center p-2">
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white", padding: 10, borderRadius: 10, minHeight: 400}} className="card flex align-items-center flex-column pt-6 px-3">
        <i className="pi pi-power-off" style={{ fontSize: "5rem", color: "var(--orange-500)" }}></i>
        {isLoading ? null : <Message style={{ width: "100%", fontWeight: "bold", marginTop: 20 }}
                                     text={`Składanie wniosków zostało tymczasowo wyłączone, system zostanie uruchomiony ${data?.value}`}
                                     severity="warn" />}

      </div>
    </div>
  );
};

export default Maintenance;
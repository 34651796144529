import React, { FC, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import "./Application.css";
import { validatePolish } from "validate-polish";
import { Image } from "primereact/image";
import {
  useCheckApplicationByPeselMutation, useDuplicateApplicationMutation,
  useGetClubsQuery, useReadAppSettingQuery, useSubmitApplicationMutation
} from "../../api/beachApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  initialApplicationState,
  selectSomethingState,
  setApplicationProperty, setData
} from "../../slices/tmpPatternSlice";
import { Message } from "primereact/message";
import UploadImageInput from "../../components/form/UploadImageInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/routes";
import { InputMask } from "primereact/inputmask";
import { ProgressSpinner } from "primereact/progressspinner";

const firstNameBlockList = [
  "Basia", "Kasia", "Zosia", "Ala", "Ela", "Ula", "Nel", "Olek", "Maks", "Bartek", "Lenka", "Lenusia", "Igorek",
  "Piotrek", "Majusia", "Majka", "Danusia", "Gabryś", "Tomek", "Stasio", "Stasiek", "Jasio", "Jaś", "Oliwka", "Cela",
  "Wojtek", "Antek", "Zuzia", "Zuzka", "Julka", "Amelka", "Amela", "Natalka", "Antosia", "Tosia", "Szymek",
  "Filipek", "Franek", "Olek", "Ignaś", "Adrianek"
];
const firstNameBlockListChecker = new RegExp(firstNameBlockList.join("|"), "i");

export const Application: FC = () => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const {data: readAppSetting, isSuccess: isReadAppSettingSuccess} = useReadAppSettingQuery({type: "SUBMIT_APPLICATION_ENABLED"});

  const agreements = (
    <>
      <Field name="accept" type="checkbox" render={({ input, meta }) => (
        <div className="field-checkbox" style={{ alignItems: "start" }}>
          <Checkbox inputId="accept" {...input} checked={input.checked !== undefined ? input.checked : false}
                    className={classNames({ "p-invalid": isFormFieldValid(meta), "p-inputtext-lg": true })}
                    style={{ marginTop: 20, marginRight: 10 }} />
          <label htmlFor="accept" className={classNames({ "p-error": isFormFieldValid(meta) })}>
            <p>Wyrażam zgodę
              na przetwarzanie przez Administratora Danych Osobowych – Mazowiecko – Warszawski Związek Piłki
              Siatkowej z siedzibą w Warszawie ul. Grażyny 13/15/8, 02-548 Warszawa, wpisanym do Krajowego
              Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział
              Gospodarczy pod numerem KRS: 0000160698, e-mail: biuro@mwzps.pl, tel. +48 577 517 474 (dalej:
              Administrator), moich danych osobowych zawartych w licencji zawodniczej siatkówki plażowej, moich
              wyników sportowych oraz danych wrażliwych w postaci zdrowia. *</p>
            <p>
              Zapoznałam/em
              się z Zasadami przetwarzania danych osobowych przez Administratora. *
            </p>
            <p>
              Potwierdzam
              zgodność powyższych danych osobowych oraz fotografii ze stanem faktycznym. *
            </p>
            <p>
              Potwierdzam
              znajomość wszystkich postanowień MWZPS i PZPS dotyczących zawodnika/trenera siatkówki plażowej i
              zobowiązuję się do ich przestrzegania. *
            </p>
            <p>
              Zobowiązuję się
              godnie reprezentować Klub i barwy narodowe pod rygorem czasowego lub bezterminowego pozbawienia
              licencji, co będzie równoznaczne z zakazem uczestnictwa w rozgrywkach MWZPS i PZPS. *
            </p>
            <p>
              Składając
              wniosek potwierdzam obowiązek opłacenia licencji zgodnie z <a target="_blank"
                                                                            href="https://mwzps.pl/wp-content/uploads/2023/03/Przepisy-sportowo-organizacyjne-w-siatkowce-plazowej-w-rozgrywkach-mlodziezowych-2023.pdf">Przepisami
              sportowo-organizacyjnymi w siatkówce plażowej w rozgrywkach młodzieżowych 2023.</a> *
            </p>
          </label>
          <label htmlFor="accept" className={classNames({ "p-error": isFormFieldValid(meta) })}></label>
        </div>
      )} />
    </>
  );

  const validate = (data: any) => {
    let errors: any = {};

    if (!data.firstName) {
      errors.firstName = "Pole jest obowiązkowe";
    }

    if (!data.lastName) {
      errors.lastName = "Pole jest obowiązkowe";
    }

    if (!data.clubId) {
      errors.clubId = "Pole jest obowiązkowe";
    }

    if (!data.email) {
      errors.email = "Pole jest obowiązkowe";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = "Nieprawidłowy adres email";
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = "Pole jest obowiązkowe";
    } else if (!/^\d+$/.test(data.phoneNumber) || data.phoneNumber.length !== 9) {
      errors.phoneNumber = "Nieprawidłowy numer telefonu! Prawidłowy format: 555555555 (9 znaków, bez spacji)";
    }

    if (!application.imagePath) {
      errors.imagePath = "Zdjęcie jest obowiązkowe!";
    }

    if (!data.accept) {
      errors.accept = "Zaznaczenie zgody jest obowiązkowe!";
    }

    return errors;
  };

  const validateDuplicate = (data: any) => {
    let errors: any = {};

    if (!data.clubId || !allClubs?.map(item => item.id).includes(data.clubId)) {
      errors.clubId = "Pole jest obowiązkowe";
    }

    if (!data.accept) {
      errors.accept = "Zaznaczenie zgody jest obowiązkowe!";
    }

    return errors;
  };

  const onApplicationSubmit = (data: any, form: any) => {
    dispatch(setApplicationProperty({ property: "firstName", value: data.firstName }));
    dispatch(setApplicationProperty({ property: "lastName", value: data.lastName }));
    dispatch(setApplicationProperty({ property: "email", value: data.email }));
    dispatch(setApplicationProperty({ property: "clubId", value: data.clubId }));
    dispatch(setApplicationProperty({ property: "phoneNumber", value: data.phoneNumber }));
    dispatch(setApplicationProperty({ property: "imagePath", value: data.imagePath }));

    submitApplication({
      submitApplicationRequest: {
        pesel: application.pesel,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        birthdayYear: Number(application.birthdayYear),
        clubId: data.clubId,
        phoneNumber: data.phoneNumber,
        imagePath: application.imagePath
      }
    });
  };

  const onDuplicateSubmit = (data: any, form: any) => {
    duplicateApplication({ duplicateApplicationRequest: { pesel: application.pesel, clubId: data.clubId } });
  };

  const isFormFieldValid = (meta: any) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error font-bold">{meta.error}</small>;
  };

  const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text"
                                                                            autoFocus
                                                                            onClick={() => setShowMessage(false)} />
  </div>;

  const dispatch = useAppDispatch();

  const { data: allClubs } = useGetClubsQuery();
  const [checkApplication] = useCheckApplicationByPeselMutation();
  const [submitApplication, {
    isSuccess: isSubmitApplicationSuccess,
    isLoading: isSubmitApplicationLoading,
    isError: isSubmitApplicationError
  }] = useSubmitApplicationMutation();
  const [duplicateApplication, {
    isSuccess: isDuplicateApplicationSuccess,
    isLoading: isDuplicateApplicationLoading,
    isError: isDuplicateApplicationError
  }] = useDuplicateApplicationMutation();

  const { application, type, errorMessage } = useAppSelector(selectSomethingState);

  useEffect(() => {
    if (!!readAppSetting?.value) {
      navigate(`/${routes.MAINTENANCE}`);
    }
  }, [isReadAppSettingSuccess])

  useEffect(() => {
    if (isSubmitApplicationError || isDuplicateApplicationError) {
      toast.error("Wystąpił błąd podczas składania wniosku, spróbuj później!");
    }

    if (isSubmitApplicationSuccess || isDuplicateApplicationSuccess) {
      navigate(`/${routes.SUBMITTED}`);
      dispatch(setData({ property: "application", value: initialApplicationState }));
      dispatch(setData({ property: "type", value: undefined }));
      dispatch(setData({ property: "errorMessage", value: "" }));

      setShowMessage(true);
    }

  }, [isSubmitApplicationError, isDuplicateApplicationError, isSubmitApplicationSuccess, isDuplicateApplicationSuccess]);

  useEffect(() => {
    if (isSubmitApplicationLoading || isDuplicateApplicationLoading) {
      window.scrollTo(0, 0);
    }
  }, [isSubmitApplicationLoading, isDuplicateApplicationLoading])

  const validatePesel = (data: any) => {
    let errors: any = {};

    if (!data.pesel) {
      errors.pesel = "Pole jest obowiązkowe";
    } else if (!validatePolish.pesel(data.pesel)) {
      errors.pesel = "Nieprawidłowy numer PESEL";
    }

    return errors;
  };

  const findYear = (pesel: string) => {
    let year = 1900 + parseInt(pesel[0]) * 10 + parseInt(pesel[1]);
    if (parseInt(pesel[2]) >= 2 && parseInt(pesel[2]) < 8)
      year += Math.floor(parseInt(pesel[2]) / 2) * 100;
    if (parseInt(pesel[2]) >= 8)
      year -= 100;

    return year.toString();
  };

  if (isDuplicateApplicationLoading || isSubmitApplicationLoading) {
    return (
      <div className="form-demo p-2">
        <div className="flex justify-content-center">
          <div className="card" style={{ backgroundColor: "white", padding: 10, borderRadius: 10 }}>
            <ProgressSpinner style={{display: "block"}} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="form-demo p-2">
      <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter}
              showHeader={false} breakpoints={{ "960px": "80vw" }} style={{ width: "30vw" }}>
        <div className="flex align-items-center flex-column pt-6 px-3">
          <i className="pi pi-check-circle" style={{ fontSize: "5rem", color: "var(--green-500)" }}></i>
          <h5>Wniosek {type === "NEW" ? "o licencję zawodniczą" : "o duplikat licencji zawodniczej"} został złożony!
            Wersja papierowa wniosku zostanie dostarczona na Twoje zawody, a o szczegółach wniosku zostaniesz
            odpowiednio poinformowany</h5>
        </div>
      </Dialog>

      <div className="flex justify-content-center">
        <div className="card" style={{ backgroundColor: "white", padding: 10, borderRadius: 10 }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Image src="/mwzps.png" width="200" style={{ marginRight: 10 }} />
            <h5 className="text-center">Licencje dla zawodników siatkówki plażowej MWZPS</h5>
          </div>

          <Form onSubmit={() => console.log()} validateOnBlur validate={validatePesel} initialValues={{
            pesel: application.pesel
          }} render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <Field name="pesel" render={({ input, meta }) => (
                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="pesel" {...input} type="number"
                                                   className={classNames({
                                                     "p-invalid": isFormFieldValid(meta),
                                                     "p-inputtext-lg": true
                                                   })}
                                                   onChange={(event) => {
                                                     dispatch(setApplicationProperty({
                                                       property: "pesel",
                                                       value: event.target.value
                                                     }));
                                                     dispatch(setData({
                                                       property: "errorMessage",
                                                       value: ""
                                                     }));
                                                     if (!validatePesel({ pesel: event.target.value }).hasOwnProperty("pesel")) {
                                                       checkApplication({ applicationValidationRequest: { pesel: event.target.value } });
                                                       const year = findYear(event.target.value);
                                                       dispatch(setApplicationProperty({
                                                         property: "birthdayYear",
                                                         value: year
                                                       }));
                                                     }
                                                   }}
                                        />
                                        <label htmlFor="pesel"
                                               className={classNames({ "p-error": isFormFieldValid(meta) })}>Wprowadź PESEL *</label>
                                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )} />
            </form>
          )} />
          {!!errorMessage ? <Message style={{ width: "100%" }} text={errorMessage} severity="error" /> : null}
          {type === "NEW" ? <Message style={{ width: "100%" }}
                                     text="Zaczynasz wypełniać nowy wniosek, uzupełnij wszystkie dane i kliknij przycisk zatwierdź"
                                     severity="success" /> : null}
          {type === "DUPLICATE" ? <Message style={{ width: "100%" }}
                                           text="Posiadasz już licencję! Jeśli zgubiłeś lub straciłeś swoją licencję, możesz wygenerować duplikat. Generowanie duplikatu wiąże się z dodatkowymi kosztami. Jesli jesteś zdecydowany, zweryfikuj aktualny klub i wypełnij wniosek poniżej."
                                           severity="warn" /> : null}

          {type === undefined ? <></> : type !== "NEW" ? <Form onSubmit={onDuplicateSubmit} initialValues={{
              clubId: application.clubId,
              accept: false
            }} validate={validateDuplicate} render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="p-fluid">
                <Field name="clubId" render={({ input, meta }) => (
                  <div className="field">
                                    <span className="p-float-label p-inputtext-lg">
                                        <Dropdown id="clubId" {...input} filter options={allClubs} optionLabel="name" className={classNames({
                                          "p-invalid": isFormFieldValid(meta),
                                          "p-inputtext-lg": true
                                        })}
                                                  optionValue="id" />
                                        <label
                                          htmlFor="clubId" className={classNames({ "p-error": isFormFieldValid(meta) })}>Klub macierzysty lub klub po transferze definitywnym *</label>
                                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )} />

                {agreements}

                <Button type="submit" label="Zatwierdź" className="mt-2" />
              </form>
            )} /> :
            <Form onSubmit={onApplicationSubmit} initialValues={{
              firstName: "",
              lastName: "",
              clubId: null,
              birthdayYear: "",
              email: "",
              phoneNumber: "",
              imagePath: "",
              accept: false
            }} validate={validate} render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="p-fluid">
                <div className="grid">
                  <Field name="firstName" render={({ input, meta }) => (
                    <div className="field md:col-6">
                                    <span className="p-float-label">
                                        <InputText id="firstName" {...input}
                                                   className={classNames({
                                                     "p-invalid": isFormFieldValid(meta),
                                                     "p-inputtext-lg": true
                                                   })} />
                                        <label htmlFor="firstName"
                                               className={classNames({ "p-error": isFormFieldValid(meta) })}>Imię *</label>
                                    </span>
                      {getFormErrorMessage(meta)}
                      {firstNameBlockListChecker.test(input.value) ?
                        <small style={{ color: "#eabb00" }} className="font-bold">Upewnij się, czy nie wprowadziłeś/aś
                          zdrobniałego imienia. Dane muszą się zgadzać z Twoim dokumentem tożsamości!</small> : null}
                    </div>
                  )} />
                  <Field name="lastName" render={({ input, meta }) => (
                    <div className="field md:col-6">
                                    <span className="p-float-label">
                                        <InputText id="lastName" {...input}
                                                   className={classNames({
                                                     "p-invalid": isFormFieldValid(meta),
                                                     "p-inputtext-lg": true
                                                   })} />
                                        <label htmlFor="lastName"
                                               className={classNames({ "p-error": isFormFieldValid(meta) })}>Nazwisko *</label>
                                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                </div>
                <Field name="birthdayYear" render={({ input, meta }) => (
                  <div className="field" hidden={true}>
                                    <span className="p-float-label">
                                        <InputText id="birthdayYear" {...input} type="text"
                                                   disabled
                                                   value={application.birthdayYear}
                                                   className={classNames({
                                                     "p-invalid": isFormFieldValid(meta),
                                                     "p-inputtext-lg": true
                                                   })} />
                                        <label htmlFor="birthdayYear"
                                               className={classNames({ "p-error": isFormFieldValid(meta) })}>Rok urodzenia *</label>
                                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="email" render={({ input, meta }) => (
                  <div className="field">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-envelope" />
                                        <InputText id="email" {...input}
                                                   className={classNames({
                                                     "p-invalid": isFormFieldValid(meta),
                                                     "p-inputtext-lg": true
                                                   })} />
                                        <label htmlFor="email"
                                               className={classNames({ "p-error": isFormFieldValid(meta) })}>Adres email *</label>
                                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )} />

                <Field name="clubId" render={({ input, meta }) => (
                  <div className="field">
                                    <span className="p-float-label p-inputtext-lg">
                                        <Dropdown id="clubId" {...input} filter options={allClubs} optionLabel="name"
                                                  optionValue="id" className={classNames({
                                          "p-invalid": isFormFieldValid(meta),
                                          "p-inputtext-lg": true
                                        })} />
                                        <label htmlFor="clubId"
                                               className={classNames({ "p-error": isFormFieldValid(meta) })}>Klub macierzysty lub klub po transferze definitywnym *</label>
                                    </span>
                  </div>
                )} />
                <Field name="phoneNumber" render={({ input, meta }) => (
                  <div className="field">
                                    <span className="p-float-label p-input-icon-right">
                                      <i className="pi pi-phone" />
                                        <InputMask id="phoneNumber" {...input} type="text"
                                                   mask={"999999999"}
                                                   className={classNames({
                                                     "p-invalid": isFormFieldValid(meta),
                                                     "p-inputtext-lg": true
                                                   })} />
                                        <label htmlFor="phoneNumber"
                                               className={classNames({ "p-error": isFormFieldValid(meta) })}>Telefon *</label>
                                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )} />

                <div style={{ display: "flex" }}>
                  <Image src="szkic.png" width="130" className="md:col-2" />
                  <div className="md:col-4"><p style={{ fontWeight: "bold" }}>Wzór zdjęcia</p>
                    <p>Dołączone zdjęcie trafia automatycznie na licencję.
                      Prosimy o wysyłanie fotografii swojej twarzy.
                      Fotografie fotografii nie będą akceptowane!</p>
                    <p style={{ fontSize: 12 }}><b>Dozwolone formaty: JPG/JPEG/PNG</b></p></div>
                  {!!application.imagePath ?
                    <Message className="md:col-6" text="Zdjęcie zostało załadowane poprawnie" severity="success" /> :
                    <Message className="md:col-6" style={{ fontWeight: "bold" }}
                             text="Brak zdjęcia, zdjęcie nie spełnia wymagań lub zbyt duży format zdjęcia!"
                             severity="error" />}
                </div>
                <UploadImageInput
                  id={"imagePath"}
                  name="imagePath"
                  label="Wgraj zdjęcie"
                  setProperty={setApplicationProperty}
                />

                <Field name="imagePath" render={({ input, meta }) => (
                  <div className="field">
                                    <span className="p-float-label hidden">
                                        <InputText id="imagePath" {...input}
                                                   className={classNames({
                                                     "p-invalid": isFormFieldValid(meta),
                                                     "p-inputtext-lg": true
                                                   })} />
                                        <label htmlFor="imagePath"
                                               className={classNames({ "p-error": isFormFieldValid(meta) })}>Link do zdjęcia *</label>
                                    </span>
                    {isFormFieldValid(meta) && application.imagePath !== "" &&
                      <small className="p-error font-bold">{meta.error}</small>}
                  </div>
                )} />
                {agreements}

                <Button type="submit" label="Zatwierdź" className="mt-2" />
              </form>
            )} />
          }
        </div>
      </div>
    </div>
  );
};

export default Application;
import { api } from "./api";
import { setAccessToken } from "../utils/userData";
import { setApplicationProperty, setData } from "../slices/tmpPatternSlice";
import { cacheTags } from "./cacheTags";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    readAppSetting: build.query<ReadAppSettingApiResponse, ReadAppSettingApiArg>({
      query: (queryArg) => ({ url: `/app-settings/${queryArg["type"]}` }),
    }),
    findApplicationById: build.query<
      FindApplicationByIdApiResponse,
      FindApplicationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/admin/applications/${queryArg.id}` }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await dispatch(setData({ property: "editApplication", value: data }));
        } catch (error) {
          console.error(error);
        }
      }
    }),
    updateApplication: build.mutation<
      UpdateApplicationApiResponse,
      UpdateApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/applications/${queryArg.id}`,
        method: "PUT",
        body: queryArg.application
      })
    }),
    deleteApplicationById: build.mutation<
      DeleteApplicationByIdApiResponse,
      DeleteApplicationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/applications/${queryArg.id}`,
        method: "DELETE"
      }),
      invalidatesTags: [cacheTags.APPLICATIONS]
    }),
    generateToken: build.mutation<
      GenerateTokenApiResponse,
      GenerateTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/authentication`,
        method: "POST",
        headers: { authorization: queryArg.authorization }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data.accessToken !== undefined) {
            setAccessToken(data.accessToken);
          }

        } catch (error) {
          console.error(error);
        }
      }
    }),
    submitApplication: build.mutation<
      SubmitApplicationApiResponse,
      SubmitApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/applications/new`,
        method: "POST",
        body: queryArg.submitApplicationRequest
      })
    }),
    duplicateApplication: build.mutation<
      DuplicateApplicationApiResponse,
      DuplicateApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/applications/duplicate`,
        method: "POST",
        body: queryArg.duplicateApplicationRequest
      })
    }),
    restoreApplication: build.mutation<
      RestoreApplicationApiResponse,
      RestoreApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/applications/${queryArg.id}/restore`,
        method: "POST"
      }),
      invalidatesTags: [cacheTags.APPLICATIONS]
    }),
    generateLicence: build.mutation<
      GenerateLicenceApiResponse,
      GenerateLicenceApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/applications/${queryArg.id}/generate`,
        method: "POST",
        responseHandler: response => response.blob(),
        cache: "no-cache",
      }),
      // @ts-ignore
      transformResponse: (
        response: GenerateLicenceApiResponse,
        meta: { request: Request; response: Response }
      ): Blob | null => {
        const fileName = meta.response.headers.get('Content-Disposition')?.split('=')[1];
        const blob = new Blob([response], { type: 'text/plain' })
        const url = URL.createObjectURL(blob);

        let hiddenElement = document.createElement('a');
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName + '';
        hiddenElement.click();

        return null;
      },
      invalidatesTags: [cacheTags.APPLICATIONS]
    }),
    cancelApplication: build.mutation<
      CancelApplicationApiResponse,
      CancelApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/applications/${queryArg.id}/cancel`,
        method: "POST",
        body: queryArg.cancelApplicationRequest,
      }),
      invalidatesTags: [cacheTags.APPLICATIONS]
    }),
    approveApplication: build.mutation<
      ApproveApplicationApiResponse,
      ApproveApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/applications/${queryArg.id}/approve`,
        method: "POST"
      }),
      invalidatesTags: [cacheTags.APPLICATIONS]
    }),
    generateLicenceBatch: build.mutation<
      GenerateLicenceBatchApiResponse,
      GenerateLicenceBatchApiArg
    >({
      query: () => ({
        url: `/admin/applications/generate-batch`,
        method: "POST",
        responseHandler: response => response.blob(),
        cache: "no-cache"
      }),
      // @ts-ignore
      transformResponse: (
        response: GenerateLicenceBatchApiResponse,
        meta: { request: Request; response: Response }
      ): Blob | null => {
        const fileName = meta.response.headers.get('Content-Disposition')?.split('=')[1];
        const blob = new Blob([response], { type: 'text/plain' })
        const url = URL.createObjectURL(blob);

        let hiddenElement = document.createElement('a');
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName + '';
        hiddenElement.click();

        return null;
      },
    }),
    approveBatchGeneration: build.mutation<
      ApproveBatchGenerationApiResponse,
      ApproveBatchGenerationApiArg
    >({
      query: () => ({
        url: `/admin/applications/approve-batch-generation`,
        method: "POST"
      }),
      invalidatesTags: [cacheTags.APPLICATIONS]
    }),
    getClubs: build.query<GetClubsApiResponse, GetClubsApiArg>({
      query: () => ({ url: `/clubs` })
    }),
    getAllClubs: build.query<GetAllClubsApiResponse, GetAllClubsApiArg>({
      query: () => ({ url: `/admin/clubs` })
    }),
    checkApplicationByPesel: build.mutation<
      CheckApplicationByPeselApiResponse,
      CheckApplicationByPeselApiArg
    >({
      query: (queryArg) => ({
        url: `/applications/check`,
        method: "POST",
        body: queryArg.applicationValidationRequest
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await dispatch(setData({ property: "type", value: data.type }));
          await dispatch(setApplicationProperty({ property: "clubId", value: data.currentClubId }));
        } catch ({ error }) {
          // @ts-ignore
          if (error.status === 400) {
            await dispatch(setData({ property: "errorMessage", value: undefined }));
            await dispatch(setData({ property: "type", value: undefined }));
          }

          // @ts-ignore
          if (error.status === 409) {
            console.log(error);
            await dispatch(setData({ property: "type", value: undefined }));
            // @ts-ignore
            await dispatch(setData({ property: "errorMessage", value: error.data.message }));
          }
        }
      }
    }),
    findApplicationList: build.query<
      FindApplicationListApiResponse,
      FindApplicationListApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/applications`,
        params: { type: queryArg["type"], status: queryArg.status }
      }),
      providesTags: (result) =>
        result
          ? [
            ...result?.map(({id}) => ({type: cacheTags.APPLICATIONS, id})),
            cacheTags.APPLICATIONS,
          ]
          : [cacheTags.APPLICATIONS],
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as enhancedApi };
export type ReadAppSettingApiResponse = /** status 200 OK */ AppSettingResponse;
export type ReadAppSettingApiArg = {
  type: "SUBMIT_APPLICATION_ENABLED";
};
export type FindApplicationByIdApiResponse = /** status 200 OK */ Application;
export type FindApplicationByIdApiArg = {
  id: string;
};
export type UpdateApplicationApiResponse = unknown;
export type UpdateApplicationApiArg = {
  id: string;
  application: Application;
};
export type DeleteApplicationByIdApiResponse = unknown;
export type DeleteApplicationByIdApiArg = {
  id: string;
};
export type GenerateTokenApiResponse =
/** status 200 OK */ AuthenticationResponse;
export type GenerateTokenApiArg = {
  authorization: string;
};
export type ApproveBatchGenerationApiResponse = unknown;
export type ApproveBatchGenerationApiArg = void;
export type SubmitApplicationApiResponse = unknown;
export type SubmitApplicationApiArg = {
  submitApplicationRequest: SubmitApplicationRequest;
};
export type DuplicateApplicationApiResponse = unknown;
export type DuplicateApplicationApiArg = {
  duplicateApplicationRequest: DuplicateApplicationRequest;
};
export type RestoreApplicationApiResponse = unknown;
export type RestoreApplicationApiArg = {
  id: string;
};
export type GenerateLicenceApiResponse = Blob;
export type GenerateLicenceApiArg = {
  id: string;
};
export type CancelApplicationApiResponse = unknown;
export type CancelApplicationApiArg = {
  id: string;
  cancelApplicationRequest: CancelApplicationRequest;
};
export type ApproveApplicationApiResponse = unknown;
export type ApproveApplicationApiArg = {
  id: string;
};
export type GenerateLicenceBatchApiResponse = Blob;
export type GenerateLicenceBatchApiArg = void;
export type GetClubsApiResponse = /** status 200 OK */ Club[];
export type GetClubsApiArg = void;
export type GetAllClubsApiResponse = /** status 200 OK */ Club[];
export type GetAllClubsApiArg = void;
export type CheckApplicationByPeselApiResponse =
/** status 200 OK */ ApplicationValidationResponse;
export type CheckApplicationByPeselApiArg = {
  applicationValidationRequest: ApplicationValidationRequest;
};
export type FindApplicationListApiResponse = /** status 200 OK */ Application[];
export type FindApplicationListApiArg = {
  type?: "NEW" | "DUPLICATE";
  status?: "NEW" | "APPROVED" | "CANCELED";
};
export type AppSettingResponse = {
  type?: "SUBMIT_APPLICATION_ENABLED";
  value?: string;
};
export type Application = {
  id?: string;
  pesel?: string;
  firstName?: string;
  lastName?: string;
  birthdayYear?: number;
  email?: string;
  clubId?: string;
  phoneNumber?: string;
  imagePath?: string;
  licenceNumber?: string;
  type?: "NEW" | "DUPLICATE";
  status?: "NEW" | "APPROVED" | "CANCELED";
  batchGenerated?: boolean;
  cancelReason?: string;
  createdDate?: string;
  approvedDate?: string;
  updatedDate?: string;
};
export type AuthenticationResponse = {
  accessToken?: string;
};
export type SubmitApplicationRequest = {
  pesel?: string;
  firstName?: string;
  lastName?: string;
  birthdayYear?: number;
  email?: string;
  clubId?: string;
  phoneNumber?: string;
  imagePath: string;
};
export type DuplicateApplicationRequest = {
  pesel?: string;
  clubId?: string;
};
export type Club = {
  id?: string;
  name?: string;
};
export type CancelApplicationRequest = {
  cancelReason?: string;
};
export type ApplicationValidationResponse = {
  type?: "NEW" | "DUPLICATE";
  currentClubId?: string;
};
export type ApplicationValidationRequest = {
  pesel?: string;
};
export const {
  useReadAppSettingQuery,
  useFindApplicationByIdQuery,
  useUpdateApplicationMutation,
  useDeleteApplicationByIdMutation,
  useGenerateTokenMutation,
  useSubmitApplicationMutation,
  useDuplicateApplicationMutation,
  useRestoreApplicationMutation,
  useGenerateLicenceMutation,
  useCancelApplicationMutation,
  useApproveApplicationMutation,
  useGenerateLicenceBatchMutation,
  useApproveBatchGenerationMutation,
  useGetClubsQuery,
  useGetAllClubsQuery,
  useCheckApplicationByPeselMutation,
  useFindApplicationListQuery
} = injectedRtkApi;

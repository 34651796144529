import React, { FC } from "react";
import { useAppDispatch } from "../../app/hooks";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Dropdown } from "primereact/dropdown";
import { SelectItemOptionsType } from "primereact/selectitem";

type TSelectInputProps = {
  id: string;
  options: SelectItemOptionsType,
  name: string;
  value: string;
  label: string;
  setProperty: ActionCreatorWithPayload<any>;
  optionValue?: string;
  optionLabel?: string;
};

const SelectInput: FC<TSelectInputProps> = ({
                                              id,
                                              options,
                                              name,
                                              value,
                                              label,
                                              setProperty,
                                              optionValue,
                                              optionLabel

                                            }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="field pt-2 pb-2 w-full">
          <span className="p-float-label">
            <Dropdown
              id={id}
              filter
              options={options}
              optionValue={optionValue}
              optionLabel={optionLabel}
              value={value}
              onChange={(e) =>
                dispatch(
                  setProperty({ property: name, value: e.target.value })
                )
              }
              className={"w-full"}
            />
            <label htmlFor={name}>{label}</label>
          </span>
      </div>
    </>
  );
};

export default SelectInput;

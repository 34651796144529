import React, { FC, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import logo from "./logo.png";
import { useGenerateTokenMutation } from "../../api/beachApi";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/routes";
import { Buffer } from "buffer";
import { getAccessToken } from "../../utils/userData";
import { Message } from "primereact/message";

const Login: FC = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();

  const [generateToken, { isSuccess, isError }] = useGenerateTokenMutation();

  useEffect(() => {
    if (isSuccess || getAccessToken()) {
      setUsername("");
      setPassword("");
      navigate(`/${routes.ADMIN}`);
    }

    if (isError) {
      console.log(isError);
    }
  }, [isSuccess]);

  const handleLogin = () => {
    generateToken({ authorization: "Basic " + Buffer.from(`${username}:${password}`).toString("base64") });
  };

  return (
    <div className="flex flex-column md:flex-row h-screen" style={{backgroundColor: "white", padding: 10, borderRadius: 10}}>
      <div className="w-full flex flex-column align-items-s justify-content-center gap-3 py-5">
        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
          <Image src={logo} alt="MWZPS" width="250" />
        </div>
        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
          <label htmlFor="username" className="w-6rem">
            Użytkownik
          </label>
          <InputText id="username" type="text" onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="flex flex-wrap justify-content-center align-items-center gap-2">
          <label htmlFor="password" className="w-6rem">
            Hasło
          </label>
          <InputText id="password" type="password" onChange={(e) => setPassword(e.target.value)} onKeyDown={(event) => {
            if (event.key === 'Enter') {
              console.log("elo");
              handleLogin();
            }
          }} />
        </div>
        <Button label="Zaloguj się" icon="pi pi-user" className="w-10rem mx-auto" onClick={handleLogin} />
        {isError ? <Message text="Niepoprawne dane logowania" severity="error" /> : null}
      </div>
    </div>
  );
};

export default Login;

import React, { FC, useEffect } from "react";
import "./Application.css";
import { Message } from "primereact/message";

export const SubmittedApplication: FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex justify-content-center p-2">
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white", padding: 10, borderRadius: 10, minHeight: 400}} className="card flex align-items-center flex-column pt-6 px-3">
        <i className="pi pi-check-circle" style={{ fontSize: "5rem", color: "var(--green-500)" }}></i>
        <Message style={{ width: "100%", fontWeight: "bold", marginTop: 20 }}
                 text="Twój wniosek został złożony. Jeśli dane we wniosku są poprawne, papierowa wersja licencji zostanie dostarczona na Twoje zawody."
                 severity="success" />
      </div>
    </div>
  );
};

export default SubmittedApplication;
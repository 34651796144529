import React, { FC } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Nav from "./components/Navbar";
import { Outlet, Route, Routes } from "react-router-dom";
import Home from "./tabs/home/Home";
import ProtectedRoute from "./utils/ProtectedRoute";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { routes } from "./app/routes";
import Login from "./tabs/login/Login";
import Application from "./tabs/application/Application";
import NewList from "./tabs/new/NewList";
import ApprovedList from "./tabs/approved/ApprovedList";
import CanceledList from "./tabs/canceled/CanceledList";
import { ToastContainer } from "react-toastify";
import SubmittedApplication from "./tabs/application/SubmittedApplication";
import ApplicationEdit from "./tabs/applicationedit/ApplicationEdit";
import Maintenance from "./tabs/application/Maintenance";

const App: FC = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <div style={{ height: "calc(100vh + 500px)", backgroundImage: "url(\"/background.jpg\")", backgroundSize: "cover" }}>
              <Application />
            </div>
          }
        />
        <Route
          path={`/${routes.SUBMITTED}`}
          element={
            <div style={{ height: "calc(100vh + 500px)", backgroundImage: "url(\"/background.jpg\")", backgroundSize: "cover" }}>
              <SubmittedApplication />
            </div>
          }
        />
        <Route
          path={`/${routes.MAINTENANCE}`}
          element={
            <div style={{ height: "calc(100vh + 500px)", backgroundImage: "url(\"/background.jpg\")", backgroundSize: "cover" }}>
              <Maintenance />
            </div>
          }
        />
        <Route
          path={`/${routes.LOGIN}`}
          element={
            <Login />
          }
        />
        <Route path={`/${routes.ADMIN}`} element={<ProtectedRoute>
          <Nav />
          <Outlet />
        </ProtectedRoute>}>
          <Route
            path=""
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${routes.NEW}`}
            element={
              <ProtectedRoute>
                <NewList />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${routes.APPROVED}`}
            element={
              <ProtectedRoute>
                <ApprovedList />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${routes.CANCELED}`}
            element={
              <ProtectedRoute>
                <CanceledList />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${routes.APPLICATIONS}/:id`}
            element={
              <ProtectedRoute>
                <ApplicationEdit />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;

import React, { FC } from "react";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/routes";
import { useApproveApplicationMutation, useCancelApplicationMutation } from "../../api/beachApi";
import { Dialog } from "primereact/dialog";
import TextInput from "../../components/form/TextInput";
import { selectSomethingState, setData } from "../../slices/tmpPatternSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Image } from "primereact/image";

const Actions: FC<{ row: any }> = ({ row }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState(false);
  const [visiblePreview, setVisiblePreview] = React.useState(false);
  const [visibleApproved, setVisibleApproved] = React.useState(false);

  const [cancelApplication] = useCancelApplicationMutation();
  const [approveApplication] = useApproveApplicationMutation();

  const dispatch = useAppDispatch();
  const { cancelReason } = useAppSelector(selectSomethingState);

  const clearCancelReasonValue = () => {
    dispatch(setData({ property: "cancelReason", value: "" }));
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Button icon={PrimeIcons.IMAGE} severity="warning" title="Podgląd zdjęcia" tooltip="Podgląd zdjęcia" tooltipOptions={{position: "left"}}
                onClick={() => setVisiblePreview(true)} />
        <Dialog
          visible={visiblePreview}
          onHide={() => {
            setVisiblePreview(false);
          }}
        >
          <Image src={`/api/images-preview/${row.imagePath}`} width="300px" />
        </Dialog>

        <Button
          icon={PrimeIcons.PENCIL}
          tooltip="Edytuj"
          tooltipOptions={{position: "left"}}
          title="Edytuj"
          onClick={() => navigate(`/${routes.ADMIN}/${routes.APPLICATIONS}/${row.id}`)}
        />
        <Button icon={PrimeIcons.TIMES} severity="danger" title="Anuluj" tooltip="Anuluj" tooltipOptions={{position: "left"}}
                onClick={() => setVisible(true)} />
        <Dialog
          visible={visible}
          onHide={() => {
            setVisible(false);
            clearCancelReasonValue();
          }}
          header="Jesteś pewny, że chcesz anulować ten wniosek?"
          footer={
            <Button
              onClick={() => {
                cancelApplication({ id: row.id, cancelApplicationRequest: { cancelReason: cancelReason } });
                setVisible(false);
                clearCancelReasonValue();
              }}
              label="OK"
            />
          }
        >
          <TextInput
            id="cancelReason"
            name="cancelReason"
            label="Powód anulowania"
            value={cancelReason}
            setProperty={setData}
            style={{ width: "100%"}}
            outerStyle={{marginTop: 20}}
          />
        </Dialog>
        <Button icon={PrimeIcons.CHECK_CIRCLE} severity="success" title="Zaakceptuj" tooltip="Zaakceptuj" tooltipOptions={{position: "left"}}
                onClick={() => setVisibleApproved(true)} />
        <ConfirmDialog
          visible={visibleApproved}
          onHide={() => setVisibleApproved(false)}
          message="Jesteś pewny, że chcesz zaakceptować ten wniosek?"
          header="Potwierdzenie"
          acceptLabel="Tak"
          rejectLabel="Nie"
          accept={() => approveApplication({ id: row.id })}
        />
      </div>
    </>
  );
};

const newListActionTemplate = (row: any) => <Actions row={row} />;
export default newListActionTemplate;

const ACCESS_TOKEN_KEY = "accessToken";

export const setAccessToken = (accessToken: string) => {
  return localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const clearAccessToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};
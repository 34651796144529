import React, { FC, ReactNode } from "react";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Column, ColumnBodyOptions } from "primereact/column";
import { useAppDispatch } from "../../app/hooks";

const TableWrapper: FC<{
  header?: string;
  sortField?: string;
  isLoading: boolean;
  data: any[] | undefined;
  columns: {
    field: string;
    header: string;
    body?: React.ReactNode | ((data: any, options: ColumnBodyOptions) => React.ReactNode);
    hidden?: boolean;
    style?: any;
  }[];
  paginator?: boolean;
  size?: number;
  page?: number;
  first?: number;
  totalElements?: number;
  setData?: any;
  button?: ReactNode;
  filter?: string;
  stateKey: string;
}> = ({
        header,
        sortField,
        isLoading,
        data,
        columns,
        paginator = false,
        size = 15,
        first = 0,
        totalElements = 1,
        setData,
        button,
        filter,
        stateKey
      }) => {
  const dispatch = useAppDispatch();

  const renderHeader = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <h4 style={{ margin: 0 }}>{header}</h4>
      {button ? button : null}
    </div>
  );

  if (isLoading) {
    return <ProgressSpinner style={{ display: "block" }} />;
  }

  return (
    <>
      <DataTable
        header={renderHeader}
        size="small"
        sortField={sortField}
        sortMode="multiple"
        resizableColumns
        sortOrder={-1}
        value={data}
        paginator={paginator}
        lazy={paginator}
        rows={size}
        first={first}
        totalRecords={totalElements}
        onPage={(event) => {
          dispatch(setData({ property: "first", value: event.first }));
          dispatch(setData({ property: "page", value: event.page }));
        }}
        globalFilter={filter}
        emptyMessage="Brak wniosków"
        stateStorage="session"
        stateKey={stateKey}
      >
        {columns.map((col) => {
          return (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              body={col.body}
              hidden={col?.hidden}
              style={col?.style}
              sortable
            />
          );
        })}
      </DataTable>
    </>
  );
};

export default TableWrapper;

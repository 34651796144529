import React, { FC, useEffect } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import approvedActionTemplate from "./Actions";
import {
  Application,
  useApproveBatchGenerationMutation,
  useFindApplicationListQuery,
  useGenerateLicenceBatchMutation
} from "../../api/beachApi";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import TextInput from "../../components/form/TextInput";
import { selectSomethingState, setData } from "../../slices/tmpPatternSlice";
import { useAppSelector } from "../../app/hooks";
import { formatDate } from "../../utils/dateUtils";
import { Badge } from "primereact/badge";
import { getYear } from "date-fns";

const columns = [
  { hidden: true, field: "firstName", header: "Imię" },
  { hidden: true, field: "lastName", header: "Nazwisko" },
  { field: "name", header: "Imię i nazwisko", body: (row: Application) => row.firstName + " " + row.lastName },
  { field: "pesel", header: "PESEL" },
  { field: "licenceNumber", header: "Numer licencji" },
  { field: "birthdayYear", header: "Rocznik" },
  { field: "clubName", header: "Klub" },
  {
    field: "type",
    header: "Typ wniosku",
    style: { textAlign: "center" },
    body: (row: Application) => {

      let type;
      let backgroundColor;

      if (!!row.createdDate && getYear(new Date(row.createdDate)) !== new Date().getFullYear()) {
        type = "Istniejący";
        backgroundColor = "cornflowerblue";
      } else {
        type = row.type === "NEW" ? "Nowy" : "Duplikat";
        backgroundColor = row.type === "NEW" ? "black" : "orange";
      }

      return (<Badge value={type}
                     style={{ backgroundColor: backgroundColor }} />);
    }
  }, {
    field: "createdDate",
    header: "Złożony",
    body: (row: Application) => row.createdDate ? formatDate(new Date(row.createdDate)) : ""
  },
  {
    field: "approvedDate",
    header: "Zaakceptowany",
    body: (row: Application) => row.approvedDate ? formatDate(new Date(row.approvedDate)) : ""
  },
  {
    field: "batchGenerated",
    header: "Paczka",
    body: (row: Application) => row.batchGenerated ?
      <i className="pi pi-times" style={{ color: "red", fontSize: "2rem" }} /> :
      <i className="pi pi-check" style={{ color: "green", fontSize: "2rem" }} />,
    style: { textAlign: "center" }
  },
  { field: "", header: "Akcje", body: approvedActionTemplate }
];

const ApprovedList: FC<{}> = ({}) => {
  const { approvedListFilter } = useAppSelector(selectSomethingState);

  const { data, refetch, isLoading, isSuccess } = useFindApplicationListQuery({ status: "APPROVED" });

  const [generateLicenceBatch, { isLoading: isGenerateLicenceLoading }] = useGenerateLicenceBatchMutation();
  const [approveBatchGeneration, { isLoading: isApproveBatchGenerationLoading }] = useApproveBatchGenerationMutation();

  useEffect(() => {
    refetch();
  }, [isSuccess]);

  return (
    <>
      <TextInput
        id="filter-approved-list"
        name="approvedListFilter"
        label=""
        value={approvedListFilter}
        setProperty={setData}
        style={{ width: "100%" }}
        outerStyle={{ marginBottom: 0 }}
      />
      <TableWrapper
        header="Wnioski zaakceptowane"
        sortField="approvedDate"
        isLoading={isLoading || isApproveBatchGenerationLoading || isGenerateLicenceLoading}
        data={data}
        columns={columns}
        button={
          <div className="flex justify-content-end">
            <Button className="ml-5" icon={PrimeIcons.CHECK_CIRCLE}
                    label={isApproveBatchGenerationLoading ? "Trwa potwierdzanie..." : "Potwierdź poprawność wygenerowanej paczki"}
                    disabled={isApproveBatchGenerationLoading}
                    onClick={() => approveBatchGeneration()} />
            <Button className="ml-5" icon={PrimeIcons.DOWNLOAD} loadingIcon={PrimeIcons.REFRESH}
                    label={isGenerateLicenceLoading ? "Trwa generowanie..." : "Generuj paczkę licencji"}
                    disabled={isGenerateLicenceLoading}
                    onClick={() => generateLicenceBatch()} />
          </div>}
        filter={approvedListFilter}
        stateKey={"approved-list"}
      />
    </>
  );
};

export default ApprovedList;

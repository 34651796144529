import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  initialEditApplicationState,
  selectSomethingState,
  setData,
  setEditApplicationProperty
} from "../../slices/tmpPatternSlice";
import {
  useFindApplicationByIdQuery,
  useGetAllClubsQuery,
  useUpdateApplicationMutation
} from "../../api/beachApi";
import { routes } from "../../app/routes";
import TextInput from "../../components/form/TextInput";
import SelectInput from "../../components/form/SelectInput";
import UploadImageInput from "../../components/form/UploadImageInput";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Message } from "primereact/message";

export const ApplicationEdit: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id = "" } = useParams();
  const { isLoading } = useFindApplicationByIdQuery({ id: id });

  const { editApplication: application } = useAppSelector(selectSomethingState);
  const { data: clubs } = useGetAllClubsQuery();

  const [editApplication, { isSuccess }] = useUpdateApplicationMutation();

  useEffect(() => {
    dispatch(setData({ property: "editApplication", value: initialEditApplicationState }));

    if (isSuccess) {
      navigate(`/${routes.ADMIN}/${routes.NEW}`);
    }
  }, [navigate, isSuccess]);

  const onSubmit = () => {
    editApplication({ id: id, application: application });
  };

  return (
    <>
      <div className="grid m-5">
        <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
          <h3 className="text-center p-2 mt-0">
            Aktualizuj dane zawodnika
          </h3>
          <Button label="Wróć" onClick={() => navigate(`/${routes.ADMIN}/${routes.NEW}`)} />
        </div>

        <TextInput
          id="pesel"
          name="pesel"
          value={application.pesel}
          label="PESEL"
          setProperty={setEditApplicationProperty}
        />
        <TextInput
          id="firstName"
          name="firstName"
          value={application.firstName}
          label="Imię"
          setProperty={setEditApplicationProperty}
        />
        <TextInput
          id="lastName"
          name="lastName"
          value={application.lastName}
          label="Nazwisko"
          setProperty={setEditApplicationProperty}
        />
        <TextInput
          id="birthdayYear"
          name="birthdayYear"
          value={application.birthdayYear}
          label="Rok urodzenia"
          setProperty={setEditApplicationProperty}
        />
        <TextInput
          id="email"
          name="email"
          value={application.email}
          label="Email"
          setProperty={setEditApplicationProperty}
        />
        <SelectInput
          id="clubId"
          options={clubs !== undefined ? clubs : []}
          name="clubId"
          optionLabel="name"
          optionValue="id"
          value={application.clubId}
          label="Klub macierzysty lub klub po transferze definitywnym"
          setProperty={setEditApplicationProperty}
        />
        <TextInput
          id="phoneNumber"
          name="phoneNumber"
          value={application.phoneNumber}
          label="Telefon"
          setProperty={setEditApplicationProperty}
        />
        <UploadImageInput
          id="imagePath"
          name="imagePath"
          label="Aktualizuj zdjęcie"
          setProperty={setEditApplicationProperty}
        />
        {application.imagePath ? <Image src={`/api/images-preview/${application.imagePath}`} width="100px" /> : null}
        {!application.imagePath ? <Message severity="error" text="Uważaj, nie ma wgranego żadnego zdjęcia! Wyjdź ze strony edycji, jeśli nie chcesz utracić fotografii!" className="w-full"></Message> : null}
        <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit} />
      </div>
    </>
  );
};

export default ApplicationEdit;
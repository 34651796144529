import React, { FC } from "react";
import { useAppDispatch } from "../../app/hooks";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Image } from "primereact/image";
import { FileUpload } from "primereact/fileupload";
import { toast } from "react-toastify";

type TUploadImageInputProps = {
  id: string;
  name: string;
  label: string;
  setProperty: ActionCreatorWithPayload<any>;
};

const UploadImageInput: FC<TUploadImageInputProps> = ({
                                                        id,
                                                        name,
                                                        label,
                                                        setProperty
                                                      }) => {
  const dispatch = useAppDispatch();

  const maxFileSize = 1024 * 1024 * 10;

  const validateFile = (file: File) => {
    if (file.size > maxFileSize) {
      toast.error("Rozmiar pliku jest zbyt duży!");
    }

    const parts = file.name.split(".");
    const extension = parts[parts.length - 1].toLowerCase();
    if (extension !== "jpeg" && extension !== "jpg" && extension !== "png") {
      toast.error("Niepoprawny format pliku: " + parts[parts.length - 1]);
    }
  };

  return (
    <>
      <FileUpload
        id={id}
        name="image"
        accept="image/jpg;image/jpeg;image/png"
        url="/api/images"
        mode="basic"
        maxFileSize={maxFileSize}
        chooseOptions={{ label: label }}
        auto
        onUpload={(e) => {
          dispatch(setProperty({ property: name, value: e.xhr.response }));
          toast.success("Zdjęcie zostało załadowane poprawnie!");
        }}
        onValidationFail={(file => {
          validateFile(file);
        })}
        onError={(e) => {
          const file = e.files[0];
          validateFile(file);
          toast.error("Wystąpił bład podczas wrzucania zdjęcia. Upewnij się, czy zdjęcie spełnia wymagania i spróbuj jeszcze raz!");
          dispatch(setProperty({ property: name, value: "" }));
        }
        }
      />
    </>
  );
};

export default UploadImageInput;

import { Outlet, useNavigate } from "react-router-dom";
import React, { FC, ReactNode } from "react";
import { getAccessToken } from "./userData";
import { routes } from "../app/routes";

export const ProtectedRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const isLoggedIn = getAccessToken() !== null;
  const navigate = useNavigate();

  if (!isLoggedIn) {
    window.location.href = routes.LOGIN;
  }

  return <>{isLoggedIn ? children : <Outlet />}</>;
};

ProtectedRoute.displayName = "ProtectedRoute";
export default ProtectedRoute;

export const routes = {
  LOGIN: "login",
  ADMIN: "admin",
  SUBMITTED: "submitted",
  MAINTENANCE: "maintenance",

  NEW: "new",
  APPROVED: "approved",
  CANCELED: "canceled",

  APPLICATIONS: "applications",
  EDIT: "edit",
};

import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import { routes } from '../routes';
import { clearAccessToken } from "../../utils/userData";

const NOT_AUTHORIZED = 401;
const FORBIDDEN = 403;

export const removeNotAuthorizedUser: Middleware = () => next => action => {
  if (isRejectedWithValue(action)) {
    const payload = action.payload;
    if (payload.status === NOT_AUTHORIZED || payload.status === FORBIDDEN) {
      clearAccessToken();
      const href = window.location.href
      if (!href.includes(routes.LOGIN)) {
        window.location.href = routes.LOGIN;
      }
    }
  }
  return next(action);
};